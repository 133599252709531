import { SFCore } from '../core/SFCore.js';
import { GrowthBook } from "@growthbook/growthbook";

SFCore.prototype.growthbook = SFCore.prototype.growthbook || {};

SFCore.prototype.growthbook.init = async (sfuid) => {
	const gbId = sfuid || crypto.randomUUID();
	const currentUrl = window.location.origin + window.location.pathname;

	const gb = new GrowthBook({
		apiHost: "https://growthbook-proxy.prod.skbx.pro",
		clientKey: "sdk-7dJZTv1TWb2yCws4",
		enableDevMode: true,
		attributes: {
			id: gbId,
			page_url: currentUrl,
		},
	});

	await gb.init({ timeout: 2000 });
	console.log("GrowthBook инициализирован");

	const featureKey = 'sf-redirect-feature';
	const evalResult = gb.evalFeature(featureKey);

	console.log("Результат evalFeature:", evalResult);

	// Отправка данных в Метрику, если сработал эксперимент
	if (evalResult.source === 'experiment' && evalResult.experimentResult?.inExperiment) {
		const sessionParams = {
			gbFeatureName: featureKey,
			gbInExperiment: evalResult.experimentResult.inExperiment,
			gbVariation: evalResult.experimentResult.variationId,
			gbValue: evalResult.experimentResult.value,
			gbExperimentKey: evalResult.experiment.key,
			gbExperimentName: evalResult.experiment.name,
		};

		await window.SFCore.executeThenReady(10, 100, 'ym', () => {
			console.log("Отправка данных в Яндекс Метрику:", sessionParams);
			ym(window.SFCore.consts.metrikaCounter, 'params', sessionParams);
		});
	} else {
		console.log("Эксперимент не сработал или фича активирована по умолчанию");
	}

	// Поведение редиректа
	const featureValue = evalResult.value;

	if (featureValue?.isRedirect && featureValue.redirectUrl) {
		console.log(`Редирект на ${featureValue.redirectUrl}`);
		window.location.href = featureValue.redirectUrl;
	} else {
		console.log("Редирект не требуется или фича неактивна");
	}
};