import { sfSuccessFormModule } from './modules/formHandler.js';
import {
	savePaidClickParamsModule,
	checkCpaChannelModule,
	checkPayChannelModule,
	updateTildautmCookieModule
} from './modules/cpa_deduplication.js';
import { Accordeon } from './modules/accordeons.js';
import { vkidModule } from './modules/vkid.js';
//import CryptoJS from 'crypto-js';
import md5 from 'md5';

import {
	PasswordStrengthMeter,
	initPasswordStrengthMeter,
} from 'vanilla-password-strength-meter/dist/vanilla-password-strength-meter.umd.js';
import 'vanilla-password-strength-meter/src/style.css';

import { cookiesConsentModule } from './modules/cookiesConsent.js';

/**
 * Класс SFCore
 * @description
 * Содержит основные методы для работы с SF
 */
class SFCore {

	/**
	 * Инициализирует SFCore
	 *
	 * Создает объект consts, содержащий:
	 * - currentUrl: URL-адрес текущей страницы
	 * - currentUtms: объект со всеми полученными UTM-метками
	 * - landingData: объект, содержащий информацию о странице, на которой находится
	 *
	 * Привязывает sfSuccessFormModule к SFCore, чтобы он мог быть вызван
	 * извне, например, document.addEventListener('SFCoreReady', async () => {
	 *     window.sfSuccessForm = window.SFCore.sfSuccessForm;
	 * });
	 */
	constructor() {
		this.isInitialized = false;
		this.consts = {
			currentUrl: new URL(window.location),
			currentUtms: {},
			landingData: {},
			debug: false,
			deduplicationCookie: 'sf_cpa_dedup',
			metrikaCounter: 38813825,
			attribution: '',
		}

		//Классы
		this.Accordeon = Accordeon;


		//привязываем модули
		this.sfSuccessForm = sfSuccessFormModule.bind(this);
		this.savePaidClickParams = savePaidClickParamsModule.bind(this);
		this.checkPayChannel = checkPayChannelModule.bind(this);
		this.checkCpaChannel = checkCpaChannelModule.bind(this);
		this.updateTildautmCookie = updateTildautmCookieModule.bind(this);
		this.cookiesConsent = cookiesConsentModule.bind(this);
		this.vkid = vkidModule.bind(this);
		// Генерируем id sf
		const sfuid = localStorage.getItem('sfuid') ||
			(crypto.randomUUID ? crypto.randomUUID() : Math.random().toString(36).substring(2) + Date.now().toString(36));
		localStorage.setItem('sfuid', sfuid);
		this.consts.sfuid = sfuid;

		//инициализируем GrowthBook как можно раньше
		if (typeof this?.growthbook?.init === "function") {
			this.growthbook.init(sfuid);
		}

		this.init();
	}


	/**
	 * Инициализирует SFCore
	 *
	 * Выполняет следующие действия:
	 * 1. Получает информацию о странице, на которой находится
	 * 2. Парсит UTM-метки из URL (если они есть)
	 * 3. Если UTM-метки не были получены, то парсит их из cookie
	 * 4. Выводит сообщение о том, что SFCore загружен
	 *
	 * @returns {Promise<void>}
	 */
	async init() {
		try {
			const response = await fetch(`https://tools.skillfactory.ru/api/get-info-by-url?url=${this.consts.currentUrl.origin + this.consts.currentUrl.pathname + '/'}`);
			const data = await response.json();
			this.consts.landingData = data[0][0] || data[0];
		} catch (error) {
			console.error('Error getting landing data: ', error);
		}

		//Object.freeze(SFCore.const);

		this.parseUtmFromUrl();
		const hasUtmKeys = ['source', 'medium', 'campaign', 'term', 'content'].some(
			param => Object.keys(this.consts.currentUtms).includes(param)
		);
		if (!hasUtmKeys) {
			this.parseUtmFromCookie();
		}

		if (this.consts.currentUrl.searchParams.has('debug') && this.consts.currentUrl.searchParams.get('debug') == 'true') {
			this.consts.debug = true;
		}

		window.dataLayer = window.dataLayer || [];

		// Устанавливаем флаг готовности
		this.isInitialized = true;

		// Вызываем кастомное событие после полной инициализации
		const event = new CustomEvent("SFCoreReady");
		document.dispatchEvent(event);

		this.logger('Init SFCore', 'success');

		const hostnameParts = this.consts.currentUrl.hostname.split('.'); // Разделяем домен на части
		const secondLevelDomain = hostnameParts[hostnameParts.length - 2]; // Получаем домен второго уровня
		if (secondLevelDomain === 'skillfactory') {
			this.consts.school = 'SF';
		} else if (secondLevelDomain === 'contented') {
			this.consts.school = 'CD';
			this.consts.metrikaCounter = 45562158;
		} else {
			this.consts.school = 'Unknown'; // Опционально, если не совпало ни с одним доменом
		}

		this.savePaidClickParams();

		const isCpaChannel = this.checkCpaChannel();
		const isPayChannel = this.checkPayChannel();

		if (isPayChannel && isCpaChannel) {
			this.consts.attribution = 'paid';
			this.setCookie(this.consts.deduplicationCookie, '', { expires: -1 });
		} else if (isPayChannel && !isCpaChannel) {
			this.consts.attribution = 'paid';
		} else if (!isPayChannel && isCpaChannel) {
			this.consts.attribution = 'cpa';
			const currentUrl = new URL(window.location);
			if (currentUrl.searchParams.has('utm_medium') && currentUrl.searchParams.get('utm_medium') === 'cpa') {
				this.updateTildautmCookie();
			} else {
				this.updateTildautmCookie();
				this.removeUtmsFromUrl();
			}
		} else {
			this.consts.attribution = 'free';
		}





		//Акционные блоки
		// Получаем все элементы с классом 'uc-event-block'
		const eventBlocks = document.querySelectorAll('.uc-event-block');

		// Пробегаем по всем найденным элементам и скрываем их
		eventBlocks.forEach(block => {
			block.style.display = 'none';
		});

		//запускаем banner manager, если он подключен
		if (window.SFCore?.bannerManager) {
			window.SFCore.bannerManager.initBannerManager();
		} else {
			console.log('BannerManager не подключен. Может быть так и задумано, кто знает...');
		}
	}

	// sfSuccessForm(form) {
	// 	sfSuccessFormModule(form);
	// }

	/**
	 * Парсит UTM-метки из URL, добавляя их в currentUtms
	 * @private
	 */
	parseUtmFromUrl() {
		// Перебираем все параметры поиска в URL
		this.consts.currentUrl.searchParams.forEach((value, key) => {
			if (key.startsWith('utm_')) {
				// Если ключ начинается с 'utm_', добавляем его в объект currentUtms
				this.consts.currentUtms[key.replace('utm_', '')] = value;
			}
			if (key.startsWith('admitad_uid') ||
				key.startsWith('tagtag_uid') ||
				key.startsWith('click_id')) {
				this.consts.currentUtms[key] = value;
			}
		});

	}

	/**
	 * Парсит UTM-метки из cookie, добавляя их в currentUtms
	 * @private
	 */
	parseUtmFromCookie() {
		var cookies = document.cookie.split('; ');
		var deduplicationCookie = cookies.find(row => row.startsWith(this.consts.deduplicationCookie + '='));
		if (deduplicationCookie) {
			var utmValuesObj = new URLSearchParams(this.getCookie(this.consts.deduplicationCookie));
			utmValuesObj.forEach((value, key) => {
				this.consts.currentUtms[key.replace('utm_', '')] = value;
			})
		} else {
			var utmCookie = cookies.find(row => row.startsWith('TILDAUTM='));
			if (utmCookie) {
				var utmValue = decodeURIComponent(utmCookie.split('=')[1]);
				// Разделяем значение куки по "|||" и парсим каждый параметр
				utmValue.split('|||').forEach(param => {
					let [key, value] = param.split('=');
					// Добавляем UTM параметры в объект, убирая префикс "utm_"
					if (key && value) {
						this.consts.currentUtms[key.replace('utm_', '')] = value;
					}

				});
			}
		}
	}

	/**
	 * Вычисляет хеш SHA-256 для переданной строки
	 * @param {string} input - строка, для которой будет вычислен хеш
	 * @returns {string} - хеш SHA-256 в виде строки, каждый байт которой
	 *    представлен в виде двух символов шестнадцатеричной системы
	 *    счисления, левый символ которого будет всегда '0', если байт
	 *    меньше 16
	 */
	calculateHash(input) {
		return md5(input);
	}

	/**
	 * Добавляет UTM-метки к data-success-url у всех форм на странице, если
	 * домен success-url отличается от домена текущей страницы
	 *
	 * @param {Object<string, string>} utms - объект, содержащий UTM-метки, например this.consts.currentUtms
	 */
	addUtmToForms(utms) {
		// Проверяем, не пустой ли объект currentUtms
		if (utms && Object.keys(utms).length > 0) {
			// Получаем все формы на странице
			const forms = document.querySelectorAll('form');
			forms.forEach(form => {
				// Получаем текущий data-success-url
				const successUrl = form.dataset.successUrl;
				if (successUrl) {
					// Создаем объект URL для текущего successUrl
					const successUrlObject = new URL(successUrl, this.consts.currentUrl.href);


					// Сравниваем домены
					if (successUrlObject.hostname !== this.consts.currentUrl.hostname) {
						// Домены различаются, добавляем UTM-параметры
						Object.entries(utms).forEach(([key, value]) => {
							successUrlObject.searchParams.set(`utm_${key}`, value);
						});

						// Обновляем data-success-url формы с новыми UTM-параметрами						
						form.dataset.successUrl = successUrlObject.toString();
					}
				}
			});
		}
	}

	/**
	 * Выводит сообщение в консоль, если debug=true
	 * @param {string} operation - описание операции
	 * @param {any} value - значение, которое будет выведено
	 */
	logger(operation, value = '') {
		if (this.consts.debug) {
			console.log(operation, value);
		}
	}

	/**
	 * Отправляет логи в бд на sf-tools
	 *
	 * @param {string} tildaRequestId - ID запроса в Tilda
	 * @param {string} operation - описание операции
	 * @param {any} value - значение, которое будет выведено
	 */
	dblogger(tildaRequestId, operation, value) {
		navigator.sendBeacon(
			'https://n8n-ha.skillfactory.tech/webhook/d78a70bb-56e7-4a0e-b763-824e6755b2ca',
			JSON.stringify({
				requestid: tildaRequestId,
				operation: operation,
				value: value
			})
		);
	}




	/**
	 * Отправляет POST-запрос на n8n с данными заявки
	 * @param {Object} payload - объект с данными заявки
	 * @property {Object} lead - объект с данными заявки
	 * @property {String} lead.name - имя
	 * @property {String} lead.phone - телефон
	 * @property {String} lead.email - email
	 * @property {String} lead.requestid - id заявки
	 * @property {String} lead.tag - метка (имя курса)
	 * @property {Number} price - полная цена курса
	 * @property {Array<String>} cookies - массив cookie
	 * @property {Object} utms - объект с UTM-метками
	 * @property {String} school - строка "SF"
	 * @property {String} referer - URL-адрес страницы, с которой была отправлена заявка
	 * @returns {Promise} - промис, который будет выполнен, если запрос будет успешно отправлен
	 */
	async sendCpaLead(payload) {
		try {
			const response = await fetch("https://n8n-ha.skillfactory.tech/webhook/cpa-leads", {
				method: 'POST',
				//mode: 'no-cors',
				body: JSON.stringify(payload)
			});
			if (this.consts.debug) {
				const testResponse = await fetch("https://n8n-ha.skillfactory.tech/webhook-test/cpa-leads", {
					method: 'POST',
					//mode: 'no-cors',
					body: JSON.stringify(payload)
				});
			}

			if (response.ok) {
				this.logger('CPA OK');
			} else {
				throw new Error("Error sending request to n8n");
			}
		} catch (error) {
			this.logger('Error sending to CPA-router', error);
		}
	}

	/**
	 * Возвращает объект с куками, где ключ - имя куки, значение - ее значение.
	 * Если значение куки отсутствует, то в объекте будет пустая строка.
	 * @returns {Object} - объект со всеми куками
	 */
	getCookiesObj() {
		const cookies = {};
		document.cookie.split(';').forEach(cookie => {
			const [key, value] = cookie.trim().split('=');
			if (key) {
				cookies[key] = value || ''; // Если значение отсутствует, присваиваем пустую строку
			}
		});
		return cookies;
	}

	redirectTo(url, tildaRequestId, target = '_self') {
		this.dblogger(tildaRequestId, 'new_script_end', Date.now());
		if (target === '_blank') {
			if (/Chrome/i.test(navigator.userAgent)) {
				window.open(url, '_blank');
			} else {
				location.href = url;
			}
		} else {
			location.href = url;
		}

	}

	/**
	 * Выполняет функцию callback, если объект с именем testObj существует в глобальном контексте.
	 * Если объект не существует, то функция будет вызываться рекурсивно с интервалом timeout,
	 * пока не будет достигнуто количество попыток attempts.
	 * @param {number} attempts - количество попыток
	 * @param {number} timeout - интервал между попытками
	 * @param {string} testObj - имя объекта, существование которого проверяется
	 * @param {function} callback - функция, которая будет вызвана, если объект существует
	 * @returns {Promise<boolean>} - true, если объект существует, false - если попытки закончились
	 */
	async executeThenReady(attempts, timeout, testObj, callback) {
		const obj = globalThis[testObj]; // Проверяем наличие объекта в глобальном контексте
		if (typeof obj !== 'undefined' && obj !== null) {
			await callback();  // Если объект существует, выполняем коллбэк и дожидаемся его завершения
			return true; // Возвращаем true
		}
		if (attempts <= 1) {
			return false; // Если попытки закончились, возвращаем false
		}
		// Ждем таймаут перед следующей попыткой
		await new Promise(resolve => setTimeout(resolve, timeout));
		// Рекурсивный вызов с уменьшением количества попыток
		return this.executeThenReady(attempts - 1, timeout, testObj, callback);
	}


	/**
	 * Возвращает значение cookie с именем name
	 *
	 * @param {string} name - имя cookie
	 * @returns {string|undefined} - значение cookie, или undefined, если cookie не существует
	 */
	getCookie(name) {
		const matches = document.cookie.match(new RegExp(
			'(?:^|; )' + name.replace(/([.$?*|{}()[]\/+^])/g, '\\$1') + '=([^;]*)'
		));
		return matches ? decodeURIComponent(matches[1]) : undefined;
	}

	/**
	 * Устанавливает значение cookie с именем name
	 *
	 * @param {string} name - имя cookie
	 * @param {string} value - значение cookie
	 * @param {object} [options] - опции cookie, могут содержать:
	 *  - {number|Date} expires - продолжительность жизни (в днях) или дата истечения
	 *  - {string} path - путь, по которому cookie будет доступна
	 *  - {string} domain - домен, на котором cookie будет доступна
	 *  - {boolean} secure - флаг, указывающий, что cookie может быть отправлена только по защищенному соединению
	 *  - {boolean} sameSite - флаг, указывающий, что cookie может быть отправлена только на тот же сайт
	 */
	setCookie(name, value, options = {}) {
		// Базовые параметры cookie
		options = {
			path: '/',  // По умолчанию путь — корень сайта
			// Применяются пользовательские опции, если они есть
			...options
		};

		// Если указана продолжительность жизни (в днях), вычисляем дату истечения
		if (options.expires instanceof Date) {
			options.expires = options.expires.toUTCString();
		} else if (typeof options.expires === 'number') {
			let date = new Date();
			date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000); // в миллисекундах
			options.expires = date.toUTCString();
		}

		// Формируем строку cookie
		let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

		// Добавляем опции к строке cookie
		for (let optionKey in options) {
			updatedCookie += "; " + optionKey;
			let optionValue = options[optionKey];
			if (optionValue !== true) {
				updatedCookie += "=" + optionValue;
			}
		}

		// Устанавливаем cookie
		document.cookie = updatedCookie;
	}

	/**
	 * Возвращает значение клиентского ID Google Analytics (G-XXXXXXX) из глобального объекта gtag,
	 * или из куки "_ga", если gtag не доступен.
	 * Использует executeThenReady, чтобы дождаться доступности объектов gtag и dataLayer.
	 * @returns {string|undefined} - клиентский ID Google Analytics, или undefined, если ID не может быть получен
	 */
	async getGCID() {
		try {
			const resultGtag = await this.executeThenReady(5, 100, 'gtag', () => {
				return new Promise((resolve) => {
					gtag('get', 'G-X08SF39ELF', 'client_id', (clientId) => {
						this.consts.gcid = clientId;
						this.addHiddenFieldsAll('cid', clientId);
						resolve(true);  // Разрешаем промис
					});
				});
			});

			if (resultGtag) {
				return this.consts.gcid;
			} else {
				const resultDataLayer = await this.executeThenReady(5, 100, 'dataLayer', () => {
					return new Promise((resolve) => {
						let gaCookie = this.getCookie('_ga');
						if (gaCookie) {
							let gaParts = gaCookie.split('.');
							this.consts.gcid = gaParts.slice(2).join('.');
							this.addHiddenFieldsAll('cid', this.consts.gcid);
						}
						resolve(true);  // Разрешаем промис
					});
				});

				if (resultDataLayer) {
					return this.consts.gcid;

				}
			}
		} catch (error) {
			console.error('Error while getting GCID:', error);
		}
	}


	async getYmId() {
		const resultYm = await this.executeThenReady(10, 100, 'ym', () => {
			return new Promise((resolve) => {
				ym(this.consts.metrikaCounter, 'getClientID', (clientId) => {
					if (clientId) {
						this.consts.ym = clientId;
						this.addHiddenFieldsAll('ycid', clientId); // Добавляем скрытое поле
						resolve(clientId);
					} else {
						resolve(undefined);
					}
				});
			});
		});

		return resultYm ? this.consts.ym : undefined;
	}


	sendMetrikaGoal(goal) {
		if (typeof ym == 'function') {
			ym(this.consts.metrikaCounter, 'reachGoal', goal);
		}
	}

	/**
	 * Добавляет скрытое поле к каждой форме на странице.
	 * @param {string} fieldName - имя поля
	 * @param {string} fieldValue - значение поля
	 */
	addHiddenFieldsAll(fieldName, fieldValue) {
		const allForms = document.querySelectorAll('form');
		allForms.forEach(form => {
			// Создаем скрытое поле
			const hiddenField = document.createElement('input');
			hiddenField.type = 'hidden';     // Поле будет скрытым
			hiddenField.name = fieldName;    // Устанавливаем имя поля
			hiddenField.value = fieldValue;  // Устанавливаем значение поля

			// Добавляем скрытое поле в форму
			form.appendChild(hiddenField);
		})
	}


	removeUtmsFromUrl() {
		const url = new URL(window.location.href);
		const params = url.searchParams;
		Array.from(params.keys()).forEach(key => {
			if (key.startsWith('utm_')) {
				params.delete(key);
			}
		});
		//url.searchParams = params;
		//window.location.href = url.toString();
		window.history.replaceState({}, document.title, url.toString());
	}


	loadScript(scriptUrl) {
		// Проверяем, подключен ли скрипт с данным URL
		function isScriptLoaded(url) {
			var scripts = document.getElementsByTagName('script');
			for (var i = 0; i < scripts.length; i++) {
				if (scripts[i].src === url) {
					return true;
				}
			}
			return false;
		}

		// Если скрипт не загружен, добавляем его
		if (!isScriptLoaded(scriptUrl)) {
			var script = document.createElement('script');
			script.src = scriptUrl;
			script.type = 'text/javascript';
			script.async = true;
			document.head.appendChild(script);
			console.log('Скрипт загружен: ' + scriptUrl);
		} else {
			console.log('Скрипт уже загружен: ' + scriptUrl);
		}
	};

	loadCSS(cssUrl) {
		// Проверяем, подключен ли CSS с данным URL и типом text/css
		function isCSSLoaded(url) {
			var links = document.getElementsByTagName('link');
			for (var i = 0; i < links.length; i++) {
				if (links[i].href === url && links[i].type === 'text/css') {
					return true;
				}
			}
			return false;
		}

		// Если CSS не загружен, добавляем его
		if (!isCSSLoaded(cssUrl)) {
			var link = document.createElement('link');
			link.href = cssUrl;
			link.rel = 'stylesheet';
			link.type = 'text/css';
			document.head.appendChild(link);
			console.log('CSS файл загружен: ' + cssUrl);
		} else {
			console.log('CSS файл уже загружен: ' + cssUrl);
		}
	};

	toggleQuizBlock(selector) {
		const quizBlocks = document.querySelectorAll(selector);
		if (quizBlocks && this.consts.currentUrl.searchParams.has('requestid')) {
			quizBlocks.forEach(quiz => {
				quiz.style.display = 'block';
			})
		} else {
			quizBlocks.forEach(quiz => {
				quiz.style.display = 'none';
			})

		}
	}


}


document.addEventListener('SFCoreReady', async () => {
	//
	window.sfSuccessForm = window.SFCore.sfSuccessForm;
	let intervalCount = 0;
	const maxIntervalCount = 10;
	let mainInterval = setInterval(() => {
		intervalCount++;
		document.querySelectorAll('.js-form-proccess').forEach(function (form) {
			form.dataset.successCallback = 'sfSuccessForm';
		});

		if (intervalCount >= maxIntervalCount) {
			clearInterval(mainInterval);
		}
	})

	const gcid = await window.SFCore.getGCID();
	const ymid = await window.SFCore.getYmId();

	// if (gcid) {
	// 	window.SFCore.addHiddenFieldsAll('cid', gcid);
	// }

	// if (ymid) {
	// 	window.SFCore.addHiddenFieldsAll('ycid', ymid);
	// }

	window.SFCore.addUtmToForms(window.SFCore.consts.currentUtms);

	//отправляем userParams из Growthbook
	if (typeof window?.SFCore?.growthbook?.sendUserParams === "function") {
		window.SFCore.growthbook.sendUserParams(window.SFCore.consts.sfuid);
	}

	window.SFCore.toggleQuizBlock('.uc-syllabus-quiz');

	//настраиваем поля паролей, если они есть на странице
	const passInput = document.querySelector('.pass-form input[name="password"]');
	const passConfirmInput = document.querySelector('.pass-form input[name="password_confirmation"]');
	if (passInput && passConfirmInput) {
		passInput.setAttribute('type', 'password');
		passConfirmInput.setAttribute('type', 'password');

		initPasswordStrengthMeter('.pass-form input[name="password"]',
			'.pass-form input[name="password_confirmation"]',
			{
				minimumLength: 6,
				showPercent: true,
			});

		const sendButton = document.querySelector('.pass-form button[type=submit]');
		sendButton.setAttribute('disabled', 'disabled');
		const sendButtonColor = sendButton.style.backgroundColor;
		sendButton.style.backgroundColor = 'gray';

		passInput.addEventListener('password.score', (e) => {
			const { score, percentage, passwordsMatch } = e.detail;
			if (score >= 33 && passwordsMatch == true) {
				sendButton.removeAttribute('disabled');
				sendButton.style.backgroundColor = sendButtonColor;
			} else {
				sendButton.setAttribute('disabled', 'disabled');
				sendButton.style.backgroundColor = 'gray';
			}
		})
	}


	// Показываем или не показываем попап куки
	if (localStorage.getItem('sfCookieConsent') !== 'true') {
		window.SFCore.cookiesConsent()
	}

	//Видео
	// Получаем div с классом video-placeholder
	const videoPlaceholder = document.querySelector('.video-placeholder');

	if (videoPlaceholder && window.SFCore.consts.videoUrl) {
		setTimeout(() => {


			// Получаем размеры div до его очистки
			const { width, height } = videoPlaceholder.getBoundingClientRect();


			// Создаем элемент видео
			const videoElement = document.createElement('video');

			// Устанавливаем атрибуты видео
			videoElement.src = window.SFCore.consts.videoUrl;
			videoElement.controls = true; // Добавляет элементы управления
			videoElement.autoplay = true; // Видео будет запускаться автоматически
			videoElement.loop = true; // Видео будет зациклено
			videoElement.muted = true; // Видео будет воспроизводиться без звука
			videoElement.setAttribute('playsinline', 'true'); // Для мобильных устройств (чтобы видео не открывалось в полноэкранном режиме)
			videoElement.setAttribute('webkit-playsinline', 'true');

			// Применяем размеры div к видео
			videoElement.style.width = `${width}px`;
			videoElement.style.height = `${height}px`;
			videoElement.style.objectFit = 'cover';

			// Убираем отступы, чтобы видео занимало весь div
			videoElement.style.objectFit = 'cover';

			// Добавляем слушатель события загрузки данных
			videoElement.addEventListener('loadeddata', () => {
				// Очищаем содержимое дива только после загрузки видео
				videoPlaceholder.innerHTML = '';
				// Вставляем видео в div
				videoPlaceholder.appendChild(videoElement);
			});

			const updateVideoSize = () => {
				const { width, height } = videoPlaceholder.getBoundingClientRect();
				videoElement.style.width = `${width}px`;
				videoElement.style.height = `${height}px`;
			};

			window.addEventListener('resize', updateVideoSize);
		}, 2000);
	} else {
		console.error('Элемент .video-placeholder не найден или ссылка на видео не задана.');
	}

	//Скрываем попап #popup-timer если utm_source=yandex
	if (window.SFCore.consts.currentUtms.source !== 'yandex') {
		const popupLink = document.querySelector('a[href="#popup-timer"]') ||
			Object.assign(document.createElement('a'), { href: '#popup-timer', style: 'display: none' });

		const zeroPopup = document.querySelector('.uc-main-popup');
		const tildaZeroPopupHelper = document.querySelector('.uc-zero-popup');

		if (zeroPopup && tildaZeroPopupHelper) {
			document.body.appendChild(popupLink);
			setTimeout(() => popupLink.click(), 60000);
		}
	}

	//отправляем постбэк hh
	if (window.SFCore.consts.currentUtms.medium == 'cpa' &&
		window.SFCore.consts.currentUtms.source == 'headhunter' &&
		window.SFCore.consts.currentUrl.searchParams.has('transaction_id')) {
		const data = {
			transaction_id: transaction_id,
			offer_id: 913,
			adv_id: SFCore.consts?.school == "SF" ? 17 : 16,
			offer_name: SFCore.landingData?.full_course_name || 'Заявка',
			action: 'REDIRECT',
		}
		const postbackUrl = `https://career.hh.ru/events?${new URLSearchParams(data).toString()}`;
		fetch(postbackUrl, {
			method: 'GET',
			mode: 'no-cors'
		}).catch(error => console.error('Failed to send load event to HH:', error))
	}


	//инициализируем vkid
	window.SFCore.vkid();


})


// Запускаем SFCore
if (typeof window !== 'undefined') {
	if (document.readyState != 'loading') {
		if (window.SFCore == undefined) {
			window.SFCore = new SFCore(); // Делаем SFCore глобальным	
		}

	} else {
		document.addEventListener('DOMContentLoaded', () => {
			if (window.SFCore == undefined) {
				window.SFCore = new SFCore(); // Делаем SFCore глобальным	
			}
		});
	}
}





export { SFCore };