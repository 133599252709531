import { SFCore } from '../core/SFCore.js';
import settings from './eventSettings.json';
//import pages from './pages.json';

SFCore.prototype.bannerManager = SFCore.prototype.bannerManager || {};

/**
 * Инициализирует баннер-менеджер.
 *
 * Методу передаются настройки для баннер-менеджера, в зависимости от школы.
 */
SFCore.prototype.bannerManager.initBannerManager = () => {

	const now = new Date(); // текущая дата и время
	const activeEvent = settings.events.find(event =>
		new Date(event.eventStart) <= now && new Date(event.eventEnd) >= now
	);
	const activeSettings = activeEvent[window.SFCore.consts.school];
	SFCore.prototype.bannerManager.settings = activeSettings;
	const eventStart = new Date(activeEvent.eventStart);
	const eventEnd = new Date(activeEvent.eventEnd);
	if (now >= eventStart && now <= eventEnd && activeSettings) {
		const notEventBlocks = document.querySelectorAll('.uc-not-event-block');
		const eventBlocks = document.querySelectorAll('.uc-event-block');
		//скрываем не акционные блоки
		if (activeEvent.eventName !== 'no-event') {

			notEventBlocks.forEach(block => {
				block.style.display = 'none';
			})
			console.log('Неакционные блоки скрыты');
		} else {

			eventBlocks.forEach(block => {
				block.style.display = 'none';
			});
			console.log('Акционные блоки скрыты');
		}

		import('./pages.json')
			.then((module) => {
				const pages = module.default || module;
				SFCore.prototype.bannerManager.settings.pages = pages[window.SFCore.consts.school];
				const link = window.SFCore.consts.currentUrl.origin + window.SFCore.consts.currentUrl.pathname;

				//
				//const eventBlocks = document.querySelectorAll('.uc-event-block');

				// Пробегаем по всем найденным элементам и отображаем их
				if (activeEvent.eventName !== 'no-event') {
					eventBlocks.forEach(block => {
						block.style.display = 'block';
					});
				}
				if (window.SFCore.bannerManager.settings.pages.includes(link)) {
					window.SFCore.bannerManager.insertBanners();
				}
			})
			.catch((error) => {
				console.error('Ошибка при загрузке JSON:', error);
			})

	}
}

/**
 * Метод вставляет баннеры на страницу.
 *
 * Метод fetch-ом загружает HTML-код страницы, указанной в settings.donor, ищет
 * на ней баннеры, определенные в settings.blockIds, и вставляет их на
 * целевую страницу. Также он загружает CSS и JS-скрипты, которые не
 * загружены на целевой странице, но присутствуют на странице донора.
 *
 * @return {void} - ничего не возвращает
 */
SFCore.prototype.bannerManager.insertBanners = function () {
	const settings = window.SFCore.bannerManager.settings;
	if (settings) {
		fetch(settings.donor)
			.then(response => {
				if (!response.ok) {
					throw new Error('SFCore.bannerManager failed to load donor');
				}
				return response.text();
			})
			.then(data => {
				let tempDiv = document.createElement('div');
				let blocksToInit = [];
				tempDiv.innerHTML = data;

				// Ищем все внешние скрипты на странице донора
				let donorScripts = tempDiv.querySelectorAll('script[src]');
				let donorCss = tempDiv.querySelectorAll('link[href][rel="stylesheet"]');
				settings.blockIds.forEach(function (blockId) {
					let blockToLoad = tempDiv.querySelector('#' + blockId);
					if (blockToLoad) {
						if (settings.replaceTags.includes(blockId)) {
							let courseNameInput = blockToLoad.querySelector('input[name="course_name"]');
							if (window.SFCore.consts.landingData.tag && courseNameInput) {
								courseNameInput.value = window.SFCore.consts.landingData.tag;
							}

						}
						let allRecords = document.querySelector("#allrecords");
						if (allRecords) {
							allRecords.insertBefore(blockToLoad, allRecords.firstChild);
							console.log("Блок " + blockId + " добавлен на целевой странице.");
						}
					} else {
						console.log("Блок " + blockId + " не найден на целевой странице.");
					}
					blocksToInit.push(blockId.replace("rec", ""));

				});
				donorCss.forEach(function (css) {
					if (!css.getAttribute('href').includes('tilda-blocks-page')) {
						window.SFCore.loadCSS(css.getAttribute('href'));
					}
				})

				// Проверяем и загружаем отсутствующие скрипты
				donorScripts.forEach(function (script) {
					let scriptSrc = script.getAttribute('src');
					window.SFCore.loadScript(scriptSrc);

				});

				if (typeof window.t_onFuncLoad === 'function') {
					settings.replaceTags.forEach(function (blockId) {
						t_onFuncLoad('t702_initPopup', function () {
							t702_initPopup(blockId.replace(/\D/g, ''));
						})

					})
				}



				// Инициализируем формы
				//window.SFCore.loadScript('https://static.tildacdn.com/js/tilda-popup-1.0.min.js');
				if (typeof window.t_forms__initForms === 'function') {
					window.t_forms__initForms();
				}
				if (typeof window.t_form_phonemask__initPhoneMask === 'function') {
					t_form_phonemask__initPhoneMask();
				}
				// if (typeof window.t_events__initEvent === 'function') {
				// 	window.t_events__initEvent();
				// }
				blocksToInit.forEach(function (blockId) {
					try {
						t396_init(parseInt(blockId));
					} catch (error) {
						console.log('Ошибка инициализации зеро-блока:', error)
					}
				})

			})
			.catch(error => {
				console.log("Ошибка при выполнении запроса:", error);
			})
	}

}
