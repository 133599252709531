import { SFCore } from '../SFCore';

/**
 * Обработчик после отправки формы.
 *
 * @param {HTMLElement} form - Форма, которую нужно отправить
 *
 * @description
 * Собирает все данные из формы, добавляет их в LocalStorage, отправляет логи в бд на sf-tools,
 * отправляет событие "order_form_submit" в Google Tag Manager
 *
 * @private
 *
 * @property {Object} formData - Собранные данные из формы
 * @property {string} storageKey - Ключ для хранения данных в LocalStorage
 * @property {string} sfToolsUrl - URL для отправки логов на sf-tools
 * @property {string} gtmEventName - Название события для отправки в Google Tag Manager
 */
export async function sfSuccessFormModule(form) {
	const requestQueue = []; // Очередь запросов
	if (!form) return;
	if (form instanceof jQuery) {
		form = form.get(0);
	}
	let tildaRequestId = form.tildaTranId;
	this.dblogger(tildaRequestId, 'new_script_start', Date.now());

	const tildaLead = {};
	const inputs = Array.from(form.elements);

	inputs.forEach(input => {
		if (input.type === 'radio' && input.checked) {
			tildaLead[input.name] = input.value;
		} else if (input.type !== 'radio') {
			tildaLead[input.name] = input.value;
		}
	});

	const { name, phone, email, rate, course_name } = tildaLead;
	const leadData = {
		sfleadname: name,
		sfleadphone: phone,
		sfleademail: email,
		sfleadrequestid: tildaRequestId,
		sfleadrate: rate,
		sfleadtag: course_name
	};
	Object.entries(leadData).forEach(([key, value]) => {
		localStorage.setItem(key, value);
	});

	const { age, extra_edu, price } = tildaLead;
	if (age && location && extra_edu && price) {
		if (
			age !== "Младше 18" && // Возраст не меньше 18
			extra_edu !== "Не планирую учиться" && // Планирует учиться
			price !== "Готов(а) учиться только бесплатно" // Не готов учиться только бесплатно
		) {
			this.sendMetrikaGoal('targetQuiz');
		}
	}

	if (tildaLead.course_name) {
		navigator.sendBeacon(
			// 'https://webhook.site/7600f87f-dd24-4763-b434-a4286ff67bc4',
			'https://tools.skillfactory.ru/api/tildalead/register',
			JSON.stringify(
				{
					tildarequestid: tildaRequestId,
				}
			)
		)
	}

	if (localStorage.getItem('sfCookieConsent') === 'true') {
		const consent = {
			name: name || '',
			email: email || '',
			phone: phone || '',
			gcid: this.consts?.gcid || '',
			ymcid: this.consts?.ym || '',
			tildarequestid: tildaRequestId || '',
			consentToCookies: true,
		};

		const consentRequest = fetch('https://tools.skillfactory.ru/api/cookies-consents', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			// mode: 'no-cors',
			body: JSON.stringify(consent),
			keepalive: true,
		});

		// Добавляем запрос в массив промисов
		requestQueue.push(consentRequest);
	}




	let formType, formTop, docHeight, formPosition;

	if (form.closest("div.t-popup")) {
		formType = "popup";
		formPosition = "popup";
	} else {
		formType = "embeded";
		formTop = form.getBoundingClientRect().top + window.scrollY; // Положение формы относительно документа
		docHeight = document.documentElement.offsetHeight; // Высота документа

		if (formTop < docHeight * 0.3) {
			formPosition = "top";
		} else if (formTop < docHeight * 0.6) {
			formPosition = "middle";
		} else {
			formPosition = "bottom";
		}
	}


	dataLayer.push({
		'event': 'order_form_submit',
		'tariff': rate,
		'form_position': formPosition,
		'form_type': formType,
		'leadId': tildaRequestId
	});

	if (this.consts.landingData.tag) {
		this?.ecommerce?.purchaseEvent(tildaRequestId, this.consts.landingData, "SF");
	}



	if (this.consts?.attribution === "cpa" &&
		!('q1' in tildaLead) && !('q2' in tildaLead) && !('q3' in tildaLead)) {
		let cookies = this.getCookiesObj();
		const { currentUtms, landingData, currentUrl } = this.consts;
		const payload = {
			"lead": {
				"name": name,
				"phone": phone,
				"email": email,
				"requestid": tildaRequestId,
				"tag": course_name,
			},
			"price": landingData?.price_full_basic,
			"cookies": cookies,
			"utms": currentUtms,
			"school": this.consts.school,
			"referer": currentUrl.href
		};

		//data объект для advcake. Вынести в отдельный метод
		if (typeof window !== "undefined") {
			const emailhash = this.calculateHash(tildaLead['email']) || this.calculateHash(tildaLead['phone']);
			window.advcake_data = window.advcake_data || [];
			window.advcake_data.push({
				pageType: 3, //оформление заявки
				user: {
					email: emailhash // email в виде хеша или уникальный идентификатор, если не передается, данное поле можно оставить пустым
				},
				currentLead: {
					leadid: tildaLead['course_name'], // статический id (курса, продукта и т.п.)
					name: landingData?.full_course_name,
					price: landingData?.price_full_basic // цена – передается если есть
				}
			});
		}

		const cpaResult = await this.sendCpaLead(payload);
	}

	const successUrl = form.dataset?.successUrl;

	if (successUrl) {
		const url = new URL(successUrl);
		url.searchParams.set('requestid', tildaRequestId);

		if (url.hostname === 'refer.id') {
			const utms = this.consts?.currentUtms;
			if (utms && typeof utms === 'object') {
				for (const [key, value] of Object.entries(utms)) {
					url.searchParams.set(`utm_${key}`, value);
				}
			}
		}

		form.dataset.successUrl = url.toString();
	}


	if (form.dataset.successUrl == undefined) { //если нет силабуса
		//flocktory
		const div = document.createElement('div');
		div.className = 'i-flocktory'; // Добавляем класс
		div.dataset.flAction = 'exchange'; // Устанавливаем атрибуты через dataset
		div.dataset.flUserName = name;
		div.dataset.flUserEmail = email;
		form.appendChild(div); // Добавляем созданный элемент в форму

		try {
			window.flocktory = window.flocktory || [];
			window.flocktory.push([
				"postcheckout",
				{
					user: { email: email },
					order: {
						id: tildaRequestId,
						price: "0",
					},
				},
			]);
		} catch (error) {
			this.logger('Flocktory error:', error);
		}

		//admitad
		try {
			admitadSyllabus(tildaRequestId);
		} catch (error) {
			this.logger('Admitad syllabus error:', error);
		}
	}

	if ('communication_method' in tildaLead) {
		if (tildaLead["communication_method"].toUpperCase() === 'В TELEGRAM') {
			var tgurl = new URL(tildaLead["tgurl"]);
			// tgurl.searchParams.set("requestid", leadId);

			// Установка success-url в data-атрибуте формы
			form.dataset.successUrl = tgurl.toString();
			// Переход на новый URL
			//window.location.href = tgurl.href;
		}
	}

	if (form.dataset?.successUrl !== undefined && !form.classList.contains('tinkoff-form')) {
		const formSuccessUrl = new URL(form.dataset.successUrl);
		formSuccessUrl.searchParams.set('requestid', tildaRequestId);
		if (formSuccessUrl.href.startsWith('https://t.me') || formSuccessUrl.href.startsWith('http://t.me') ||
			(typeof tildaLead['target'] !== 'undefined' && tildaLead['target'] == '_blank')) {

			try {
				admitadSyllabus(tildaRequestId);
			} catch (error) {
				this.logger('Admitad syllabus error:', error);
			}
			Promise.all(requestQueue).then(() => {
				this.redirectTo(formSuccessUrl.href, tildaRequestId, "_blank");
			})

		} else {
			Promise.all(requestQueue).then(() => {
				this.redirectTo(formSuccessUrl.href, tildaRequestId);
			})
		}

	};


	this.dblogger(tildaRequestId, 'new_script_end', Date.now());
}