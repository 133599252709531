export function vkidModule() {
	if (window.location.hostname !== 'skillfactory.ru') {
		console.log('vkidModule: не инициализирован (не skillfactory.ru)');
		return;
	}
	const tagExists = !!this?.consts?.landingData?.tag;
	const isSF = this?.consts?.school === "SF";
	const isMain = this?.consts?.landingData?.is_main;

	if (!tagExists || !isSF || !isMain) {
		console.log('vkidModule: не инициализирован');
		return;
	}

	const script = document.createElement('script');
	script.src = 'https://cdn.skillbox.pro/frontend-libs/autolead/2.0.0/index.min.js';
	script.onload = () => {
		const options = {
			appId: 53002640,
			autologinAction: 'https://tools.skillfactory.ru/api/exchange-token',
			langId: 'ru',
			redirectPage: 'https://skillfactory.ru/vk-autologin',
			onNotification: console.log,
			onError: console.log,
			agreementLink: 'https://skillfactory.ru/position_of_user_personal_data',
			debug: true
		};

		if (window.autoLeadApp) {
			new window.autoLeadApp(options).init();
		} else {
			console.error('autoLeadApp не найден в window');
		}
	};
	script.onerror = () => {
		console.error('Не удалось загрузить autoLeadApp');
	};

	document.head.appendChild(script);
}
