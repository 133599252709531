export function cookiesConsentModule() {
	const style = document.createElement('link');
	style.rel = 'stylesheet';
	style.href = 'https://tools.skillfactory.ru/css/cookiesConsentPopup.css';
	document.head.appendChild(style);

	const popup = document.createElement('div');
	popup.className = 'cookie-popup cookie-popup_visible';

	const content = document.createElement('div');
	content.className = 'cookie-popup__content';

	const text = document.createElement('p');
	text.className = 'cookie-popup__text';
	text.innerHTML = `Нажимая кнопку &laquo;ОК&raquo; или продолжая посещение и&nbsp;использование сайта я&nbsp;даю согласие на&nbsp;обработку файлов сооkіе. Запретить обработку cookie вы&nbsp;можете через браузер. Подробнее в&nbsp;<a href="https://disk.yandex.ru/i/S7UnLzPZ9JZdaw">Политике</a>`;

	const button = document.createElement('button');
	button.className = 'cookie-popup__button';
	button.textContent = 'ОК';

	button.addEventListener('click', () => {
		localStorage.setItem('sfCookieConsent', 'true'); // Сохраняем согласие
		popup.remove(); // Убираем попап
	});

	content.appendChild(text);
	content.appendChild(button);
	popup.appendChild(content);
	document.body.appendChild(popup);
}